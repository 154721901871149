import * as React from "react";
import { graphql } from "gatsby";

import { GatsbyImage, getImage, ImageDataLike } from "gatsby-plugin-image";
import { MDXRenderer } from "gatsby-plugin-mdx";

import { Container, Row, Col } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper";
import Layout from "../components/layout";

import * as styles from "../styles/index.module.css";

import { RouteComponentProps } from "@reach/router";

interface IndexQueryProps extends RouteComponentProps {
  data: {
    strapiIndex: {
      logoText: string;
      aboutDescription: {
        data: {
          childMdx: {
            body: string;
          };
        };
      };
      engAboutDescription: {
        data: {
          childMdx: {
            body: string;
          };
        };
      };
      background: {
        localFile: ImageDataLike;
      };
    };
    allStrapiArtist: {
      nodes: {
        slug: string;
        image: {
          localFile: ImageDataLike;
        };
        name: string;
      }[];
    };
  };
}

export default function IndexPage({ data, location }: IndexQueryProps) {
  const { strapiIndex, allStrapiArtist } = data;

  return (
    <Layout
      lang="hu"
      pageMeta={{ title: "Kezdőlap" }}
      background={strapiIndex.background?.localFile}
      location={location}
      defaultLang={"hu"}
    >
      <Container className={styles.index_carousel_wrapper} fluid>
        <Row className={`w-80`}>
          <Col>
            <Swiper
              slidesPerView={"auto"}
              loopedSlides={allStrapiArtist.nodes.length}
              centeredSlides
              spaceBetween={30}
              navigation
              autoplay={{ delay: 5000, disableOnInteraction: true }}
              loop
              modules={[Autoplay, Navigation]}
              breakpoints={{
                578: {
                  slidesPerView: 1.5,
                  spaceBetween: 50,
                },
                992: {
                  slidesPerView: 2,
                  spaceBetween: 50,
                },
                1400: {
                  slidesPerView: 2.5,
                  spaceBetween: 100,
                },
              }}
            >
              {allStrapiArtist.nodes.map((node) => {
                const artistImage = getImage(node.image.localFile)!;
                return (
                  <SwiperSlide key={node.slug}>
                    <GatsbyImage
                      loading="eager"
                      image={artistImage}
                      alt={node.name}
                      imgClassName={styles.index_carousel_element_image}
                    />
                    <p className={styles.index_carousel_element_text}>
                      {node.name}
                    </p>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </Col>
        </Row>
      </Container>

      <Container className={styles.index_about_wrapper} fluid>
        <Row className={`w-80`}>
          <Col>
            <h2>Rólunk</h2>
          </Col>
        </Row>
        <Row className={`${styles.index_about_content} w-80`}>
          <Col>
            <MDXRenderer>
              {strapiIndex.aboutDescription.data?.childMdx.body ?? ""}
            </MDXRenderer>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
}

export const query = graphql`
  query IndexQuery {
    strapiIndex {
      aboutDescription {
        data {
          childMdx {
            body
          }
        }
      }
      engAboutDescription {
        data {
          childMdx {
            body
          }
        }
      }
      background {
        localFile {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED)
          }
        }
      }
    }
    allStrapiArtist(
      filter: { highlightOrder: { ne: null } }
      sort: { fields: highlightOrder }
    ) {
      nodes {
        slug
        image {
          localFile {
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                transformOptions: { cropFocus: CENTER }
                aspectRatio: 1
              )
            }
          }
        }
        name
      }
    }
  }
`;
